import React from "react"
import { Link, withPrefix } from "gatsby"

const tag = ({data}) => {
    const tags = []
    if (data.night_capacity > 0) {
        tags.push('夜間課程・午後部あり')
    }
    return (
        <ul>
            {tags.map((tag) => (
                <li>#{tag}</li>
            ))}
        </ul>
    )
}

const SchoolTag = {
    Title: ({children}) => {
        return (
            <div className="c-page-title">
                <h1>{children}</h1>
            </div>
        )
    },
    Card: ({data}) => {
        const {school_name, slug, school_id, order, school_type, corporate_name, prefecture_name, municipalities, list_heading} = data
        const isSimple = order > 10000
        return (
            <div className="c-school-item">
                <Link to={slug.charAt(0) === "/" ? slug : `/${slug}`}>
                    {!isSimple && (
                        <figure>
                            <img
                            src={withPrefix(`/img/school/${school_id}/list.jpg`)}
                            alt={school_name}
                            width="345"
                            height="150"
                            />
                        </figure>
                    )}
                    <div className="c-school-item-data">
                        <span>{school_type} / {prefecture_name}{municipalities}{corporate_name ? ` / ${corporate_name}` : ""}</span>
                        <h2>{school_name}</h2>
                        {!isSimple && (
                            <p>
                                {list_heading}
                            </p>
                        )}
                        {tag({data})}
                    </div>
                </Link>
            </div>
        )
    }
}

export default SchoolTag