import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Paging from "../components/paging"
import SchoolTag from "../components/school-tag"
import { NavListItem, SchoolAreaBlock } from "../components/category-block"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export default function SchoolListArea({ data, pageContext }) {
  const { currentPage, numPages, areaKey, areaSortList, areaSortPrefectures } = pageContext
  const category = 'school'
  const { area } = useSiteMetadata()
  const schools = data.allSchool.edges
  const areaName = area[areaKey]
  return (
    <Layout slug={`/${category}/${areaKey}/`} category={category} wrapperAddClassName="u-bgc-gray" showSchoolArea={false} showJob={false} showCharm={false} {...data.meta.nodes[0]} metaReplace={{area_name: areaName}}>
      <SchoolTag.Title>{areaName}の歯科衛生士学校</SchoolTag.Title>
      <div className="c-school-nav">
        {NavListItem({sortList: areaSortPrefectures, countData: data.prefectures.group, linkUrl: (areaKey === 'hokkaido' ? `/${category}` : `/${category}/${areaKey}`)})}
      </div>
      <div className="p-school-items">
        <div className="p-school-items-title">
          <div>{areaName}の歯科衛生士学校 一覧</div>
          <div><span>{data.allSchool.totalCount}</span>件</div>
        </div>
        {schools.map((school) => (
          <SchoolTag.Card data={school.node} />
        ))}
        <Paging path={`/${category}/${areaKey}`} currentPage={currentPage} numPages={numPages} />
      </div>
      <SchoolAreaBlock countData={data.area.group} areaSortList={areaSortList} />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!, $areaKey: String!) {
    meta: allMeta(filter: {slug: {eq: "/school/kanto/"}}) {
      nodes {
        title
        description
        keywords
      }
    }
    allSchool(
      sort: { order: [ASC, DESC], fields: [order, updated] }
      limit: $limit
      skip: $skip
      filter: { area: { eq: $areaKey } }
    ) {
      edges {
        node {
          slug
          school_id
          school_type
          school_name
          corporate_name
          area
          prefecture_name
          municipalities
          order
          night_capacity
          list_heading
        }
      }
      totalCount
    }
    area: allSchool(filter: { prefecture: { ne: "" } }) {
      group(field: area) {
        fieldValue
        totalCount
      }
    }
    prefectures: allSchool(filter: { area: { eq: $areaKey } }) {
      group(field: prefecture) {
        fieldValue
        totalCount
      }
    }
  }
`